import { useTranslation } from "react-i18next";
import React from "react";
import TitleMain from "../../components/main/title-main";

export default function OurSolution() {
  const { t } = useTranslation();
  return (
    <div
      className="md:mt-28 mt-12 "
      style={{
        background: "linear-gradient(0deg, #E2EBFE 0%, rgba(226, 235, 254, 0) 100%)"
      }}>
      <TitleMain src="/assets/icons/title-idea.svg" title={t("home_title_solutions")} />
      <div className="flex flex-col sm:flex-row h-5/6 2xl:px-48 md:px-12 sm:px-6 px-3 sm:justify-between items-center mt-12 ">
        <div className="flex flex-col md:items-start items-center sm:block">
          <h1 className="2xl:text-6xl lg:text-4xl sm:text-2xl md:text-3xl text-xl font-semibold mb-6 text-center md:text-start">
            {t("home_sol_develop_sign_lang_interpreter")}
          </h1>
          <p className="mb-6 sm:text-start text-center">
            {t("home_sol_desc_1")} <strong>{t("home_sol_desc_2")}</strong> <br /> {t("home_sol_desc_3")}
            <br /> {t("home_sol_desc_4")}
            <strong>
              {" "}
              {t("home_sol_desc_5")}
              <br /> {t("home_sol_desc_6")}{" "}
            </strong>
            {t("home_sol_desc_7")}
          </p>
          <img alt="" className="h-14" src="/assets/icons/circle-love.svg" />
        </div>
        <img alt="" className="md:w-96 xl:w-max" src="/assets/anu-official.png" />
      </div>
    </div>
  );
}
