import React, { useState } from "react";
import TitleMain from "../components/main/title-main";
import UnityView from "../components/UnityView";
import axios from "axios";
import Footer from "../sections/footer";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { CLASSIFIER_URL, HANDPRO_API_URL, SL2TXT_URL } from "../api";
import Lottie from "react-lottie";
import animationData from "../assets/lotties/brain.json";
import RecordVideo from "../components/main/recordVideo";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

const arrayTranslate = [8, 5, 6, 13, 7, 10, 11, 12, 14, 16, 1, 2, 8, 4, 15, 18, 17, null, null];

// [
// "одоо асуугаарай", 10
// "баярлалаа", 11
// "баяртай", 8
// "оройн мэнд", 13
// "сайхан амраарай", 1
// "сайна байна уу", 2
// "шалгаж байна ", 4
// "сайна байна уу би виртуал дохионы хэлмэрч ану байна", 0
// "сайна уу !---эсүүл--- баяртай",
// "та юуунд дуртай вэ?", 5
// "танд асуулт байна уу", 6
// "танд таалагдаж байна уу", 7
// "2 уулаа танилцая", 3
// "түр баяртай", 8
// "өдрийн мэнд", 14
// "уучлаарай", 9
// "би завтай байна", 16
// "завтай юу" 15
// ];

export default function TryIt() {
  const { t } = useTranslation();
  const [activeMove, setActiveMove] = useState({ value: null });
  const [text, setText] = useState([]);
  const [slVideo, setSlVideo] = useState(null);
  const [loadingText, setLoadingText] = useState("");
  const [loadingTextToSign, setLoadingTextToSign] = useState("");
  const [translatedText, setTranslatedText] = useState("");

  async function sendRequest(text) {
    setLoadingTextToSign(t("loading"));
    const res = await axios.get(`${CLASSIFIER_URL}?text=${text}`, {
      headers: { "client-token": process.env.REACT_APP_CLASSIFIER_CLIENT_TOKEN }
    });
    setActiveMove({ value: arrayTranslate[res.data] });
    setLoadingTextToSign("");
  }

  async function handleTakePhoto (dataUri) {
    const imageKey = uuid() + ".png";
    const putUrlRes = await axios.post(`${HANDPRO_API_URL}/sl2text-signed-url`, {
      contentType: 'image/png',
      bucketKey: '1',
      key: imageKey
    });

    const putObjRes = await axios.put(putUrlRes.data.response, await (await fetch(dataUri)).blob(), {
      contentType: 'image/png',
    });

    if (putObjRes.status !== 200) {
      console.log("S3 upload failed");
      return;
    }
    
    const cashAssistResponse = await axios.post(`${HANDPRO_API_URL}/ai/cash-assist`, {
      objKey: imageKey
    });

    if (cashAssistResponse.status === 200 && cashAssistResponse.data.audioUrl) {
      (new Audio(cashAssistResponse.data.audioUrl)).play();
    }
  }

  function convertToText() {
    if (!slVideo) {
      alert(t("try_video_to_text_label"));
      return;
    }
    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(slVideo);
      reader.onload = async (fileOnloadEvent) => {
        setLoadingText(t("try_uplading_the_video") + "...");
        const videoKey = uuid() + "." + slVideo.type.split("/")[1];
        const putUrlRes = await axios.post(`${HANDPRO_API_URL}/sl2text-signed-url`, {
          contentType: slVideo.type,
          key: videoKey
        });

        const putObjRes = await axios.put(putUrlRes.data.response, fileOnloadEvent.target.result, {
          contentType: slVideo.type
        });

        if (putObjRes.status === 200) {
          setLoadingText(t("try_ai_is_processing") + "...");
          const getUrlRes = await axios.get(`${HANDPRO_API_URL}/sl2text-signed-url?key=${videoKey}`);

          const sl2textResponse = await axios.post(`${SL2TXT_URL}`, {
            video_path: getUrlRes.data.response,
            video_key: videoKey
          });

          if (sl2textResponse.status === 200) {
            setTranslatedText(sl2textResponse.data.Output.join(" "));
          } else {
            console.log("AI failed processing the video");
          }
        } else {
          console.log("S3 upload failed");
        }
        setLoadingText("");
      };
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  return (
    <>
      <div className="md:text-2xl 2xl:px-48 md:px-12 sm:px-6 px-3">
        <div className="ml-6 mb-12 mt-16">
          <TitleMain toLeft title={t("Try it out")} src="/assets/icons/circle-translate.svg" />
        </div>
        <div className="flex p-6 border rounded-3xl bg-gray-50 justify-between flex-wrap gap-5 xl:gap-10 relative overflow-hidden">
          {
            <div
              className={`delay-1000 z-50 ${
                loadingTextToSign !== "" ? "flex opacity-100" : "hidden "
              } opacity-0 transition-all duration-300 fixed top-0 w-full h-full left-0 bg-black bg-opacity-25 backdrop-blur-[4px] justify-center items-center"`}>
              <div className="flex justify-center items-center flex-col gap-3">
                <div className="w-[50vw] h-[50vw] max-h-[50vh]">
                  <Lottie options={{ loop: true, autoplay: true, animationData: animationData }} />
                </div>
                <p>{loadingTextToSign}</p>
              </div>
            </div>
          }
          <div className="flex-1 w-full xl:max-w-xl">
            <h1 className=" mb-8 text-lg font-semibold">{t("Please enter the text to convert sign language")}</h1>
            <p className="text-base">{t("cold start info")}</p>
            <br />
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="bg-gray-400 bg-opacity-20 rounded-xl w-full p-4 lg:mb-10"
              rows={5}
              placeholder={t("")}></textarea>

            <button
              onClick={() => {
                sendRequest(text);
              }}
              className="hidden lg:flex items-center gap-2 px-6 py-3  rounded-full border-2 border-blue-200 bg-blue-600 text-white translation-all hover:bg-blue-400 duration-200">
              <img alt="" src="/assets/icons/play.svg" />
              {t("try_btn_convert_to_sign_lang")}
            </button>
          </div>
          <div className="relative w-full lg:w-2/5">
            <div className="absolute top-0 left-0 bottom-0 right-0 bg-black bg-opacity-0"></div>
            <UnityView data={activeMove} className="w-full h-[500px] lg:h-[600px] " />
          </div>
          <div className="w-full flex lg:hidden">
            <button
              onClick={() => {
                sendRequest(text);
              }}
              className="flex lg:hidden items-center gap-2 px-6 py-3  rounded-full border-2 border-blue-200 bg-blue-600 text-white translation-all hover:bg-blue-400 duration-200">
              <img alt="" src="/assets/icons/play.svg" />
              {t("try_btn_convert_to_sign_lang")}
            </button>
          </div>
        </div>
        <div className="mt-10 flex p-6 border rounded-3xl bg-gray-50 justify-between">
          <div className="flex-1 ">
            <h1 className=" mb-8 text-lg font-semibold">{t("Please upload the video to convert to text")}</h1>
            {/* <input type="file" onChange={(e) => setSlVideo(e.target.files?.item(0))} /> */}
            <RecordVideo
              onComplete={async (e) => {
                let b = await e;
                // convert blob to file
                var file = new File([b], "video.mp4", {
                  type: "video/mp4"
                });
                setSlVideo(file);
              }}
              loadingText={loadingText}
              onConvert={convertToText}
              translatedText={translatedText}
              setSlVideo={setSlVideo}
              slVideo={slVideo}
            />
            {/* {loadingText !== "" && <p>{loadingText}</p>} */}

            {/* {translatedText !== "" && <h3>{translatedText}</h3>} */}
          </div>
        </div>
        <div className="mt-10 flex p-6 border rounded-3xl bg-gray-50 justify-between">
          <Camera onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } } />
        </div>
        {/* <div className="mt-10 flex p-6 border rounded-3xl bg-gray-50 justify-between ">
          <div className="flex-1 max-w-xl">
            <h1 className=" mb-8 text-lg font-semibold">{t("Please upload the video to convert to text")}</h1>
            <input type="file" onChange={(e) => setSlVideo(e.target.files?.item(0))} />
            {loadingText !== "" && <p>{loadingText}</p>}
            <button
              disabled={loadingText !== ""}
              onClick={() => {
                convertToText();
              }}
              className="flex items-center gap-2 px-6 py-3 mt-5 rounded-full border-2 border-blue-200 bg-blue-600 text-white translation-all hover:bg-blue-400 duration-200">
              <img alt="" src="/assets/icons/play.svg" />
              {t("Convert to text")}
            </button>
            {translatedText !== "" && <h3>{translatedText}</h3>}
          </div>
        </div> */}
      </div>
      <div className="mt-24">
        <Footer />
      </div>
    </>
  );
}
