import { useTranslation } from "react-i18next";
import React from "react";

export default function AppAbout() {
  const { t } = useTranslation();
  return (
    <div
      className="2xl:px-52 md:px-20 sm:px-12 px-6 py-12 flex justify-between bg-blue-100 sm:items-center items-start sm:mb-36 mb-8 flex-col sm:flex-row
    ">
      <div>
        <h1 className="font-normal sm:text-md text-sm mb-4">
          {t("home_about_app_desc_1")}
          <br />
          {t("home_about_app_desc_2")}
        </h1>
        <h1 className="font-bold uppercase sm:text-6xl text-xl sm:mb-12 mb-4">
          {t("home_about_app_title_1")} <br />
          {t("home_about_app_title_2")} <br /> {t("home_about_app_title_3")}
        </h1>
        <p className="text-base">{t("home_desc_virtual_interpreter_app")}</p>
        <p className="text-base font-bold mb-6">{t("stay_tuned")}</p>
        <div className="flex gap-4 mb-8 sm:flex-row flex-col">
          <button className="flex gap-3 px-5 py-3 border-solid border rounded-full bg-blue-200">
            <img alt="" className="h-6" src="/assets/icons/apple-logo.svg" /> Apple store
          </button>
          <button className="flex gap-3 px-5 py-3 border-solid border rounded-full bg-cyan-100">
            <img alt="" className="h-6" src="/assets/icons/google-play-logo.svg" />
            Google play
          </button>
        </div>
      </div>
      <img alt="" src="/assets/2-iphone.png"></img>
    </div>
  );
}
