import React from "react";
import Footer from "../sections/footer";
import Latest from "../sections/posts/latest";

export default function Posts() {
  return (
    <>
      <div className=" mt-16">
        <Latest />
      </div>
      <Footer />
    </>
  );
}
