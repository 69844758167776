import React, { useEffect, useState } from "react";
import ShareButton from "../../components/share-button";
import { useTranslation } from "react-i18next";
import { extractDate, HANDPRO_CDN_URL, listNews } from "../../api";

export default function Posts() {
  const { t } = useTranslation();
  const [newsList, setnewsList] = useState([]);

  useEffect(() => {
    async function fetchNewsList() {
      const list = await listNews(null);
      if (list) {
        setnewsList(list);
      } else {
        console.log("List news error");
      }
    }
    fetchNewsList();
  }, []);

  return (
    <div className="2xl:px-48 md:px-20 sm:px-12 px-6 mb-16 xl:mt-36 md:mt-20 mt-8">
      <h1 className="sm:text-3xl text-xl font-semibold uppercase sm:mb-10 mb-6">{t("header_news")}</h1>
      {newsList.length > 0 ? (
        <>
          <div className="gap-5 grid lg:grid-cols-3 sm:grid-cols-2">
            {newsList.map((post, index) => (index > 0 && index < 4 ? <PostCard post={post} index={index} /> : null))}
          </div>
          <div className="flex justify-center sm:mt-12 mt-6">
            <ShareButton title={t("home_btn_all_news")} to="/posts" />
          </div>
        </>
      ) : (
        t("loading")
      )}
    </div>
  );
}
export function PostCard({ index, post }) {
  return (
    <div key={index} className="rounded-3xl border overflow-hidden">
      <img alt="" src={`${HANDPRO_CDN_URL}/${post.thumbnail}`} className="w-full" />
      <div className="py-6 px-4 ">
        <h1 className="text-xl line-clamp-1 overflow-hidden">{post.title}</h1>
        <p className="line-clamp-2 mt-3 mb-4">{post.content}</p>
        <div className="flex justify-between">
          <p className="text-xs font-extrabold">{extractDate(post.insertDate)}</p>
          {/* <div className="flex items-center gap-3">
            <img alt="" className="h-4" src="/assets/icons/share.svg" />
            <p className="text-xs font-extrabold">qwe</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}
