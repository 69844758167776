import { t } from "i18next";
import React from "react";
import TitleMain from "../../components/main/title-main";

const teamMembers = [
  {
    name: "М. Ханддорж",
    position: "AI судлаач",
    src: "/assets/members/Photo5.png"
  },
  {
    name: "Г. Оргилболд",
    position: "Үүсгэн байгуулагч, CEO",
    src: "/assets/members/co1der.jpg"
  },
  {
    name: "Т. Өнөболд",
    position: "Зөвлөх, Cofounder",
    src: "/assets/members/unubold.png"
  },
  {
    name: "М. Тэргэл",
    position: "AI судлаач",
    src: "/assets/members/Photo6.png"
  },
  {
    name: "Б. Хишигбаяр",
    position: "Fullstack engineer",
    src: "/assets/members/khb.jpg"
  },
  {
    name: "Г. Сарнай",
    position: "Дата багийн ахлах",
    src: "/assets/members/bish.jpg"
  },
  {
    name: "Г. Мөнхцэцэг",
    position: "Дохионы хэлмэрч",
    src: "/assets/members/mura.jpg"
  },
  {
    name: "Д. Батхишиг (je3f0o)",
    position: "Computer scientist coding expert",
    src: "/assets/members/jeefoo.jpeg"
  },
  {
    name: "М. Нурбахит",
    position: "3D Unity & software engineer",
    src: "/assets/members/nurbahit.jpg"
  },
  {
    name: "М. Баасансүрэн",
    position: "3D creator",
    src: "/assets/members/avatar.jpeg"
  },
  {
    name: "А. Баасанжав",
    position: "Зөвлөх",
    src: "/assets/members/Photo3.png"
  },
  {
    name: "Ц. Бямбасүрэн",
    position: "Sign interpreter",
    src: "/assets/members/Photo4.png"
  },
  {
    name: "Б. Анужин",
    position: "Data collector",
    src: "/assets/members/Photo10.png"
  }
];

export default function OurTeam() {
  return (
    <div className="2xl:mx-48 md:mx-20 sm:mx-12 mx-6 mb:mt-56 mt-8 sm:mt-16">
      <TitleMain title={t("about_our_team")} src="/assets/icons/circle-love-blue.svg" toLeft />
      <div className="mt-6 sm:mt-16 gap-4 sm:gap-11 flex flex-wrap justify-between">
        {teamMembers.map((member, index) => (
          <MemberProgile key={index} {...member} />
        ))}
      </div>
    </div>
  );
}

function MemberProgile({ src, name, position }) {
  return (
    <div className="flex flex-col gap-3 w-50 items-center w-48">
      <img alt="" src={src} className="w-40 h-40 object-cover object-top rounded-full" />
      <h1 className="font-semibold sm:text-[22px] text-lg px-2 text-center">{name}</h1>
      <p className="font-normal sm:text-lg text-sm px-2 text-center">{position}</p>
    </div>
  );
}
