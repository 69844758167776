import React from "react";
import ShareButton from "../components/share-button";
import Title from "../sections/ourProjects/Title";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Pagination, Autoplay } from "swiper";
import Dividedashed from "../components/divide-dashed";
import Footer from "../sections/footer";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
export default function OurProjects() {
  const { t } = useTranslation();
  const slideImages = [
    "/assets/slideshow-images/slideshow-1.png",
    "/assets/slideshow-images/slideshow-2.png",
    "/assets/slideshow-images/slideshow-3.png"
  ];
  const roadToAbilityImages = [
    "/assets/road-to-ability-images/1.png",
    "/assets/road-to-ability-images/2.png",
    "/assets/road-to-ability-images/3.png"
  ];
  const projectData = [
    {
      title: t("projects_job_subtitle"),
      problem: t("projects_job_desc_1"),

      solution: t("projects_job_desc_2"),
      image: "/assets/project-images/work.png"
    },
    {
      title: t("projects_emergency_subtitle"),
      problem: t(t("projects_emergency_desc_1")),
      solution: t("projects_emergency_desc_2"),
      image: "/assets/project-images/emergency.png"
    },
    {
      title: t("projects_online_courses_subtitle"),
      problem: t(t("projects_online_courses_desc_1")),
      solution: t("projects_online_courses_desc_2"),
      image: "/assets/project-images/course.png"
    }
  ];
  return (
    <>
      <div className=" sm:pt-16">
        <SpecialCard
          title={t("projects_title_disability_expo")}
          shortText={t("projects_disability_expo_short_text")}
          desc={t("projects_disability_expo_desc")}
          images={slideImages}
          link={"https://www.facebook.com/search/top?q=disability%20expo%20mongolia"}
        />
        <Dividedashed />
        {projectData.map((data, index) => {
          return (
            <>
              <div className="2xl:px-48 px-6">
                <ProjectCard key={index} title={data.title} image={data.image} problem={data.problem} solution={data.solution} />
              </div>
              <Dividedashed />
            </>
          );
        })}
        <SpecialCard
          title={t("projects_road_to_ability_subtitle")}
          shortText={" "}
          desc={t("projects_road_to_ability_desc")}
          images={roadToAbilityImages}
          link={"https://www.facebook.com/profile.php?id=100089801266418"}
        />
        <Dividedashed />
      </div>
      <Footer />
    </>
  );
}
export function SpecialCard({ shortText, desc, title, images = [], link }) {
  return (
    <div className="py-10 2xl:px-48 md:px-12 sm:px-6 px-3">
      <Title title={title} />
      <h3 className="py-6 sm:py-10 md:w-1/2 text-base sm:text-lg font-normal text-coolGray-800 opacity-70">{shortText}</h3>
      {/* Images */}
      {/* <div className="w-auto"> */}
      <Swiper
        spaceBetween={30}
        pagination={{
          clickable: true
        }}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false
        }}
        className="mySwiper max-w-[1132px] mx-0">
        {images[0] &&
          images.map((image, index) => {
            return (
              <SwiperSlide key={index} className="max-w-[1162px]">
                <img alt="" className="rounded-xl mb-10 w-full h-3/6" src={image} />
              </SwiperSlide>
            );
          })}
      </Swiper>
      <h3 className="sm:w-1/2 text-base sm:text-lg font-normal text-coolGray-800 opacity-70 py-6 sm:py-10">{desc}</h3>
      <ShareButton title={t("btn_detail")} to={link} />
    </div>
  );
}
export function ProjectCard({ image, title, problem, solution }) {
  return (
    <div className="pb-0 sm:py-12">
      <Title title={title} />
      <div className="flex justify-center items-start py-0 sm:py-10 flex-col md:flex-row">
        <img alt="" src={image} className="w-fit pb-12" />
        <div>
          <h3 className="xl:pl-40 md:pl-12 pl-8 lg:w-2/3 w-full font-normal text-coolGray-800 opacity-70">{problem}</h3>
          <h3 className="xl:pl-40 md:pl-12 pl-8 lg:w-2/3 w-full font-normal text-coolGray-800 pb-0 sm:pb-10 py-10">{solution}</h3>
        </div>
      </div>
    </div>
  );
}
