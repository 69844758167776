import React, { useEffect, useRef } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

export default function UnityView({ data = 0, className }) {
  const unityRef = useRef(null);
  const { unityProvider, sendMessage } = useUnityContext({
    loaderUrl: "/assets/unity/Build/Build.loader.js",
    dataUrl: "/assets/unity/Build/Build.data",
    frameworkUrl: "/assets/unity/Build/Build.framework.js",
    codeUrl: "/assets/unity/Build/Build.wasm"
  });

  useEffect(() => {
    if (data?.value) {
      try {
        sendMessage("Anu_A_pose", "PlayAnimation", data?.value.toString());
      } catch (e) {
        console.log(e);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  return <Unity unityProvider={unityProvider} ref={unityRef} devicePixelRatio={2} className={className} />;
}
