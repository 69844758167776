import React from "react";
import Footer from "../sections/footer";

export default function Feedback() {
  return (
    <>
      <div className="bg-blue-50 px-48 pt-16 mb-12">
        <img alt="" src="/assets/icons/3d-heart.svg" />
        <h3 className="uppercase font-semibold text-gray-800 text-2xl pt-6">бидэнд санал хүсэлт илгээгээрэй</h3>
        <h5 className="text-base text-gray-800 opacity-70 pt-4 font-normal">Бидний ажлыг дэмжиж байгаа танд баярлалаа.</h5>
        <div className="grid grid-cols-2 gap-24">
          <div></div>
          <img alt="" src="/assets/icons/hello-Anu.svg" />
        </div>
      </div>

      <Footer />
    </>
  );
}
