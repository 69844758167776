import { useEffect } from "react";
import { useRecordWebcam } from "react-record-webcam";
import { useTranslation } from "react-i18next";

export default function RecordVideo({ onComplete, onConvert, loadingText, translatedText, setSlVideo = () => {}, slVideo }) {
  const { t } = useTranslation();
  const recordWebcam = useRecordWebcam({ frameRate: 60 });

  const saveFile = async () => {
    const blob = await recordWebcam.getRecording();
    const formData = new FormData();
    formData.append("video", blob, "video.webm");
    // return file
    onComplete(blob);
  };

  useEffect(() => {
    recordWebcam.open();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (recordWebcam.status === "PREVIEW") {
      saveFile();
    }
    // eslint-disable-next-line
  }, [recordWebcam.status]);

  return (
    <div className="flex gap-10 justify-between flex-wrap lg:flex-nowrap">
      <div className="w-full">
        <p className={"absolute z-10 drop-shadow-sm shadow-white"}>Camera status: {recordWebcam.status}</p>
        {/* <button onClick={recordWebcam.open}>Open camera</button> */}
        <div className="relative">
          {/* {recordWebcam.status === "PREVIEW" ? ( */}
          <video
            ref={recordWebcam.previewRef}
            autoPlay
            muted
            loop
            className={"absolute w-full h-full " + (recordWebcam.status === "PREVIEW" ? "opacity-100" : "opacity-0")}
          />
          {/* // ) : ( */}
          <video ref={recordWebcam.webcamRef} className="bg-black" autoPlay muted />
          {/* )} */}
        </div>
        <div>
          <div className="flex justify-center items-center gap-4 mt-5 flex-wrap">
            {recordWebcam.status === "RECORDING" ? (
              <button
                onClick={recordWebcam.stop}
                className="bg-red-500  text-white font-bold   h-8 w-8 hover:shadow-[0_0_0px_5px] transition-all duration-200 hover:shadow-red-300"></button>
            ) : recordWebcam.status === "PREVIEW" ? null : (
              <button
                onClick={recordWebcam.start}
                //red record button classNames
                className="bg-red-500  text-white font-bold  rounded-full h-10 w-10 hover:shadow-[0_0_0px_5px] transition-all duration-200 hover:shadow-red-300 p-1"></button>
            )}

            {recordWebcam.status === "PREVIEW" && (
              <button onClick={recordWebcam.retake}>
                <img alt="" src="/assets/icons/restart.svg" className="h-8" />
              </button>
            )}
            <button
              onClick={() => {
                let input = document.createElement("input");
                input.type = "file";
                input.accept = "video/*";
                input.onchange = (e) => {
                  setSlVideo(e.target.files?.item(0));
                };
                input.click();
              }}
              className="ml-1 flex items-center gap-2 px-4 py-1 rounded-full border-2 border-blue-200 box-border  text-white translation-all bg-blue-600 hover:bg-blue-400 duration-200">
              <img alt="" src="/assets/icons/upload-to-cloud.svg" className="h-10" />
            </button>
            <button
              disabled={!slVideo && (loadingText !== "" || recordWebcam.status !== "PREVIEW")}
              onClick={() => {
                onConvert();
              }}
              className={
                (!slVideo && (loadingText !== "" || recordWebcam.status !== "PREVIEW") ? "opacity-50 " : "opacity-100 ") +
                " flex items-center gap-2 px-6 py-2  rounded-full border-2 border-blue-200  text-white translation-all bg-blue-600 hover:bg-blue-400 duration-200"
              }>
              <img alt="" src="/assets/icons/play.svg" />
              {t("try_btn_convert_to_text")}
            </button>

            {/* click to upload video button */}

            {/* <button onClick={recordWebcam.download}>Download recording</button> */}
            {/* <button onClick={saveFile}>Save file to server</button> */}
          </div>
          {/* // <input
          //   type="file"
          //   className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          //   onChange={(e) => setSlVideo(e.target.files?.item(0))}
          // /> */}
        </div>
      </div>
      <div className="w-full">
        {/* auto multiple line text area */}

        <textarea
          value={loadingText !== "" ? loadingText : translatedText}
          // onChange={(e) => setValue(e.target.value)}
          readOnly
          placeholder="Орчуулсан текст"
          className="w-full text-base sm:text-lg md:text-xl bg-gray-100 p-6 border-2 rounded-r-3xl rounded-tl-3xl overflow-auto"
          rows={4}
        />
        {/* <div className="flex gap-4 sm:mt-12 mt-8 sm:flex-row flex-col">
          <button
            onClick={() => {
              // song.current.play(audio);
              alert(t("coming soon"));
            }}
            className="flex sm:min-w-max max-w-max items-center gap-3 px-6 rounded-full py-4 bg-blue-700 text-white">
            <img alt="" className="w-6 " src="/assets/icons/play.svg" />
            {t("home_btn_listen_translation")}
          </button>
        </div> */}
      </div>
    </div>
  );
}
