import { useTranslation } from "react-i18next";
import React from "react";
import TitleMain from "../../components/main/title-main";

export default function Partners() {
  const { t } = useTranslation();

  const partnersData = [
    { title: t("home_partner_mstars"), logo: "/assets/logos/mstars-logo.png" },
    {
      title: t("home_partner_edu_sci_ministry"),
      logo: "/assets/logos/bolovsrolyam.png"
    },
    {
      title: t("home_partner_info_center"),
      logo: "/assets/logos/mshut.png"
    },
    { title: "Metaland Metaverse", logo: "/assets/logos/metaland.jpg" },
    { title: t("home_partner_noorog"), logo: "/assets/logos/noorog.jpg" },
    { title: "Start venture builder", logo: "/assets/logos/start.png" },
    { title: t("home_partner_deaf_edu_ngo"), logo: "/assets/logos/deafedu.png" },
    {
      title: t("home_partner_national_center_against_violence"),
      logo: "/assets/logos/heut.png"
    },
    { title: t("home_partner_procraft"), logo: "/assets/logos/procraft.png" },
    { title: t("home_partner_tegsh_tusgal"), logo: "/assets/logos/equalNgo.png" },
    // { title: "ЦойлогсоZ", logo: "/assets/logos/tsoilogsox.png" },
    { title: t("home_partner_tenger_tv"), logo: "/assets/logos/tengertv.png" },
    {
      title: t("home_partner_mn_sign_lang_association"),
      logo: "/assets/logos/masli.png"
    }
  ];
  return (
    <div className="2xl:px-48 md:px-12 sm:px-6 px-3 sm:pt-12 pt-2">
      <TitleMain src="/assets/icons/building.svg" title={t("home_title_partners")} />
      <div className="grid sm:grid-cols-4 grid-cols-2 sm:gap-6 gap-2 sm:pt-12 pt-6 pb-12 ">
        {partnersData.map((partner, index) => {
          return <PartnerCard key={index} src={partner.logo} title={partner.title} />;
        })}
      </div>
    </div>
  );
}
export function PartnerCard({ src, title }) {
  return (
    <div className="flex justify-center items-center flex-col hover:border-slate-100  transition-all duration-200 border-2  py-4 border-white rounded-2xl">
      <img alt="" src={src} className="pb-3 w-32" />
      <h5 className="text-center">{title}</h5>
    </div>
  );
}
