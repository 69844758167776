import { t } from "i18next";
import { useState, useCallback } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Footer from "../sections/footer";
export default function Donate() {
  const [paymentMethod, setPaymentMethod] = useState(0);
  const paymentMethodData = [
    { logo: "/assets/icons/bank.svg", name: t("donate_option_bank_title") },
    { logo: "/assets/icons/socialpay.svg", name: "SocialPay" },
    { logo: "/assets/icons/qpay.svg", name: "QPay" }
  ];
  return (
    <div>
      <div
        style={{
          backgroundImage: `url("/assets/icons/1.svg")`,
          backgroundSize: "100%"
        }}
        className="h-56 2xl:px-48 md:px-20 sm:px-12 px-6 py-8">
        <div>
          <img alt="" src="/assets/icons/care.svg" className="pb-6" />
          <h3 className="uppercase font-normal md:2xl text-xl leading-10">
            {t("donate_title_text_1")} <span className="font-bold">{t("donate_title_text_2")} </span> <br></br>
            {t("donate_title_text_3")}
          </h3>
        </div>
      </div>
      <div className="grid md:grid-cols-2 mb:gap-32 gap-4 bg-blue-50 pt-10 sm:pb-72 pb-12 2xl:px-48 md:px-20 sm:px-12 px-6">
        <div className=" ">
          <div className="grid grid-cols-3 gap-4">
            {paymentMethodData.map((data, index) => {
              return (
                <PaymentCard
                  key={index}
                  name={data.name}
                  logo={data.logo}
                  onClick={() => {
                    setPaymentMethod(index);
                  }}
                />
              );
            })}
          </div>
          <div>
            {(() => {
              switch (paymentMethod) {
                case 0:
                  return <CardInformation />;
                case 1:
                  return <div></div>;
                case 2:
                  return <div></div>;
                default:
                  return <CardInformation />;
              }
            })()}
          </div>
        </div>
        <div>
          <div className="px-8 py-8 bg-white border-2 border-gray-200 rounded-xl">
            <h2 className=" uppercase font-semibold text-blueGray-900 text-sm">🌱 {t("donate_title_by_support_us")} </h2>
            <DonateText desc={t("donate_support_equality")} />
            <DonateText desc={t("donate_support_status_work")} />
            <DonateText desc={t("donate_support_first_app")} />
          </div>
          <div className="py-4 px-8 mt-3 bg-red-50 border-2 border-gray-200 rounded-xl">
            <img alt="" src={"/assets/icons/mail-heart.svg"} />
            <h2 className="font-semibold text-lg py-3">{t("donate_appriciation_title")}</h2>
            <h3 className="font-normal text-sm text-yellow-700">{t("donate_appriciation_desc")}</h3>
          </div>
        </div>
      </div>
      <div className="pt-14">
        <Footer />
      </div>
    </div>
  );
}
export function PaymentCard({ logo, name, onClick, value }) {
  return (
    <button
      onClick={onClick}
      className="flex flex-col h-24 bg-white items-center justify-center px-3 py-4 rounded-xl cursor-pointer hover:border-blue-600 hover:text-blue-600 border-gray-200 border-2 transition-all duration-200 ">
      <img alt="" src={logo} />
      <h3 className="pt-3  font-bold text-sm">{name}</h3>
    </button>
  );
}

export function DonateText({ desc }) {
  return (
    <div className="flex items-start pt-4">
      <img alt="" src="/assets/icons/check-alone.svg" />
      <h3 className="px-4 font-normal text-sm">{desc}</h3>
    </div>
  );
}

// Payment method

export function CardInformation() {
  return (
    <div className="py-10">
      <h3 className="font-normal text-xl pb-5">Дансны мэдээлэл</h3>
      <div>
        <CardtInfoDetail title={"Банкны нэр"} name={"Голомт банк"} />
        <CardtInfoDetail title={"Дансны дугаар"} name={"2025134368"} />
        <CardtInfoDetail title={"Дансны нэр"} name={"Хайнд про ХХК"} />
      </div>
    </div>
  );
}
export function CardtInfoDetail({ title, name }) {
  const [value, setValue] = useState("");
  // eslint-disable-next-line
  const [copied, setCopied] = useState(false);

  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);
  return (
    <div className="flex items-center justify-between ">
      <div>
        <h4 className="font-bold text-slate-700 text-sm">{title}</h4>
        <h3 className="text-base font-semibold py-2">{name}</h3>
      </div>
      <CopyToClipboard onCopy={onCopy} text={value}>
        <button onClick={() => setValue(name)} className="px-5 py-2 border-2 border-blue-200 rounded-3xl hover:bg-blue-100">
          Хуулах
        </button>
      </CopyToClipboard>
    </div>
  );
}
