import { t } from "i18next";
import React from "react";
import TitleMain from "../../components/main/title-main";

export default function Roadmap() {
  return (
    <div
      className="py-28 "
      style={{
        background: "linear-gradient(0deg, #E2EBFE 0%, rgba(226, 235, 254, 0) 100%)"
      }}>
      <div className="2xl:px-48 md:px-20 sm:px-12 px-6">
        <TitleMain title={t("about_our_purpose")} src="/assets/icons/circle-flag.svg" toLeft />
      </div>
      <div className="2xl:px-48 md:px-20 sm:px-12 px-6 overflow-auto scrollbar-hide ">
        <img alt="" src="/assets/icons/roadmap.svg" className="h-[436px] xl:h-auto w-auto min-w-max xl:w-full mt-12 aspect-[1128/438]" />
      </div>
    </div>
  );
}
