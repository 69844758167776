import { useTranslation } from "react-i18next";
import React from "react";

export default function OurAmbition() {
  const { t } = useTranslation();
  return (
    <div className="2xl:px-48 md:px-20 sm:px-12 px-6 mt-28">
      <h1 className="uppercase text-2xl">{t("about_our_ambition_title")}</h1>
      <div>
        <h2 className="relative mt-10 sm:mt-24 text-xl text-gray-400 font-semibold ml-9 sm:ml-20">
          <img alt="" src="/assets/icons/double-quote.svg" className="w-5 absolute bottom-full right-full -translate-x-4" />
          {t("about_our_ambition_quote_1")} <br /> {t("about_our_ambition_quote_2")}
        </h2>
        <h3 className="mb:mt-24 mt-8 text-gray-400 text-base mb:ml-20 ">{t("about_our_ambition_desc")}</h3>
      </div>
    </div>
  );
}
