import React from "react";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className="2xl:px-48 md:px-20 sm:px-12 px-6 flex flex-wrap sm:flex-col flex-row items-center">
      <div className="flex justify-between items-center flex-wrap py-4 w-full">
        <div className="w-full sm:w-auto">
          <img alt="" src="/assets/icons/handpro-logo.svg" className="sm:block m-auto sm:ml-0" />
          <p className="sm:text-start text-center text-lg sm:mt-10 mt-4 mb-2 md:mb-8">
            {t("footer_virtual_sign_language_interpreter_app")}
            <br />
            <b>{t("stay_tuned")}</b>
          </p>
          <div className="flex gap-4 py-4 sm:py-0 flex-wrap justify-center sm:justify-start">
            <button className="flex gap-3 px-4 sm:px-5 py-3 border-solid border rounded-full bg-blue-200">
              <img alt="" className="h-6" src="/assets/icons/apple-logo.svg" /> Apple store
            </button>
            <button className="flex gap-3 px-4 sm:px-5 py-3 border-solid border rounded-full bg-cyan-100">
              <img alt="" className="h-6" src="/assets/icons/google-play-logo.svg" />
              Google play
            </button>
          </div>
        </div>
        <div className="mb-0 sm:mb-8 sm:block w-full sm:w-auto">
          <div className="hidden sm:block">
            <h1 className="mb-8 text-base font-extrabold">{t("footer_contact_us")}</h1>
            <a href="mailto:info@handpro" className="flex items-center gap-4">
              <img alt="" src="/assets/icons/message.svg" />
              info@handpro.mn
            </a>
            <a href="tel:+97694047874" className="flex items-center gap-4 mt-3 mb-8">
              <img alt="" src="/assets/icons/phone.svg" />
              9404-7874
            </a>
          </div>
          <div className="flex mb-4 sm:mb-8 justify-center sm:justify-start">
            <a href="https://www.facebook.com/handpro.tech" target={"_blank"} rel="noreferrer">
              <img alt="" src="/assets/icons/fb.svg" />
            </a>
            <a href="https://www.youtube.com/watch?v=RKfZUqiT1qw&t=191s" target={"_blank"} rel="noreferrer">
              <img alt="" src="/assets/icons/youtube.svg" />
            </a>
            <a href="https://www.instagram.com/handpro.vsli/" target={"_blank"} rel="noreferrer">
              <img alt="" src="/assets/icons/instagram.svg" />
            </a>
            <a href="https://www.linkedin.com/company/hand-pro-technologies/" target={"_blank"} rel="noreferrer">
              <img alt="" src="/assets/icons/linkedin.svg" />
            </a>
            <a href="https://www.tiktok.com/" target={"_blank"} rel="noreferrer">
              <img alt="" src="/assets/icons/tiktok.svg" />
            </a>
          </div>
          {/* <Link to={"/feedback"}> */}
          <button className="hidden *flex items-center gap-2 px-6 py-3 rounded-full border-2 border-blue-200 translation-all hover:bg-blue-100">
            <img alt="" src="/assets/icons/inbox.svg" />
            {t("Feedback")}
          </button>
          {/* </Link> */}
        </div>
      </div>
      <div className="w-full hidden sm:block">
        <div className="flex w-full justify-between sm:flex-row flex-col border-t border-gray-100 pt-8 pb-8 sm:items-start items-center ">
          <div className="flex gap-8">
            <h1 className="text-sm font-bold">{t("footer_service_condition")}</h1>
            <h1 className="text-sm font-bold">{t("footer_security_policy")}</h1>
          </div>
          <div>
            <p className="text-sm sm:text-start text-center py-4 sm:py-0 ">Бүх эрх хуулиар хамгаалагдсан © 2023 он. Хэйнд Про ХХК</p>
          </div>
        </div>
      </div>
    </div>
  );
}
