import React from "react";
import TitleMain from "../../components/main/title-main";
import { useTranslation } from "react-i18next";

export default function ProblemsSolution() {
  const { t } = useTranslation();
  const cardData = [
    {
      icon: "/assets/icons/book.svg",
      title: t("home_problems_edu_subtitle"),
      desc: t("home_problems_edu_desc")
    },
    {
      icon: "/assets/icons/building-icon.svg",
      title: t("home_problem_service_subtitle"),
      desc: t("home_problem_service_desc")
    },
    {
      icon: "/assets/icons/briefcase-alt.svg",
      title: t("home_problem_workplace_subtitle"),
      desc: t("home_problem_workplace_desc")
    },
    {
      icon: "/assets/icons/message-circle-lines.svg",
      title: t("home_problem_info_subtitle"),
      desc: t("home_problem_info_desc")
    },
    {
      icon: "/assets/icons/users.svg",
      title: t("home_problem_communication_subtitle"),
      desc: t("home_problem_communication_desc")
    },
    {
      icon: "/assets/icons/face-frown.svg",
      title: t("home_problem_discrimination_subtitle"),
      desc: t("home_problem_discrimination_desc")
    }
  ];
  return (
    <div className="sm:pt-28 pt-8 2xl:px-48 md:px-20 sm:px-12 px-6 bg-blue-50">
      <TitleMain src="/assets/icons/check.svg" title={t("home_title_problems")} />
      <div className="pt-14 pb-28 grid sm:grid-cols-2 sm:gap-6 gap-3">
        {cardData.map((item, index) => {
          return <ProblemCard key={index} icon={item.icon} title={item.title} desc={item.desc} />;
        })}
      </div>
    </div>
  );
}
export function ProblemCard({ icon, title, desc }) {
  return (
    <div className=" px-6 py-6 rounded-3xl bg-white">
      <img alt="" src={icon} />
      <h3 className="text-2xl font-medium py-1.5">{title}</h3>
      <h5 className="text-sm text-gray-600">{desc}</h5>
    </div>
  );
}
