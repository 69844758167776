import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import TitleMain from "../../components/main/title-main";
import { PostCard } from "../home/posts";
import { extractDate, HANDPRO_CDN_URL, listNews } from "../../api";

export default function Latest() {
  const { t } = useTranslation();
  const [newsList, setnewsList] = useState([]);

  useEffect(() => {
    async function fetchNewsList() {
      const list = await listNews(null);
      if (list) {
        setnewsList(list);
      } else {
        console.log("List news error");
      }
    }
    fetchNewsList();
  }, []);

  return (
    <div className="2xl:px-48 md:px-12 sm:px-6 px-3">
      <div className="pl-6">
        <TitleMain toLeft src="/assets/icons/circle-post.svg" title={t("news_title_latest")} />
      </div>
      {newsList.length > 0 ? (
        <>
          <div className="hidden mt-12 rounded-3xl sm:flex overflow-hidden bg-gray-100">
            <img
              alt=""
              src={`${HANDPRO_CDN_URL}/${newsList[newsList.length - 1].thumbnail}`}
              className="font-semibold h-[350px] min-h-full lg:h-96 aspect-[13/9.5] object-cover"
            />
            <div className="pl-6 pr-8 py-10 flex flex-col justify-center">
              <h1 className="text-3xl line-clamp-2">{newsList[newsList.length - 1].title}</h1>
              <h2 className="text-base font-light mt-4 sm:mt-8 mb-6 lg:mb-12 line-clamp-5">{newsList[newsList.length - 1].content}</h2>
              <h3 className="text-sm font-bold">{extractDate(newsList[newsList.length - 1].insertDate)}</h3>
            </div>
          </div>

          <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
            {newsList.map((post, index) => (index >= 1 && index <= 12 ? <PostCard post={post} key={index} /> : null))}
          </div>
          <div className="flex justify-center mt-12 mb-48">
            <ReactPaginate
              className="flex gap-3"
              // onPageChange={(data) => {
              //   console.log(data);
              // }}
              initialPage={0}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              renderOnZeroPageCount={null}
              pageCount={1}
              activeClassName="bg-blue-600 text-white"
              pageClassName="border cursor-pointer h-10 w-10 [&_a]:items-center [&_a]:justify-center [&_a]:flex rounded-full hover:bg-blue-100 [&_a]:h-[100%] [&_a]:w-[100%]"
              previousLabel={<img alt="" src="/assets/icons/left.svg" className="h-10 w-10 rounded-full hover:bg-blue-100 duration-200" />}
              nextLabel={<img alt="" src="/assets/icons/right.svg" className="h-10 w-10 rounded-full hover:bg-blue-100 duration-200" />}
            />
          </div>
        </>
      ) : (
        t("loading")
      )}
    </div>
  );
}
