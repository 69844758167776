import React from "react";
import TitleMain from "../../components/main/title-main";
import { useTranslation } from "react-i18next";

export default function WhyWeDidIt() {
  const { t } = useTranslation();
  return (
    <div className="pt-28  2xl:px-48 md:px-12 sm:px-6 px-3 ">
      <TitleMain src="/assets/icons/message-circle.svg" title={t("home_title_why_we_created")} />
      <h2 className="text-center sm:mt-6 mt-4 font-bold text-sm">{t("home_in_mongolia_2023")}</h2>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-6 py-8">
        <BorderCard number={"106'000+"} title={t("home_special_needs_people_count")} />
        <BorderCard number={"8'400+"} title={t("home_hearing_impairment_people_count")} />
        <BorderCard number={"4'300+"} title={t("home_speech_impairment_people_count")} />
        <BorderCard number={"20+"} title={t("home_mn_sign_language_interpreters_count")} bgColor={"bg-rose-50"} />
      </div>
      <img alt="" src="/assets/why-pc.svg" className="hidden lg:block w-full mt-12" />
      <img alt="" src="/assets/why-tablet.svg" className="hidden md:block lg:hidden w-full mt-12" />
      <img alt="" src="/assets/why-tablet-small.svg" className="hidden sm:block md:hidden w-full mt-12" />
      <img alt="" src="/assets/why-phone.svg" className="block sm:hidden w-full" />
    </div>
  );
}
export function BorderCard({ number, title, bgColor = "bg-gray-100" }) {
  return (
    <div className={"px-3 py-3 sm:px-6 sm:py-6 rounded-3xl " + bgColor}>
      <h1 className="font-bold text-2xl">{number}</h1>
      <h3 className="sm:font-bold font-font-semibold text-xs mt-2">{title}</h3>
    </div>
  );
}
