import React from "react";
import Footer from "../sections/footer";
import CreateValue from "../sections/header/create-value";
import AppAbout from "../sections/home/app-about";
import IntroSection from "../sections/home/intro-section";
import MstarsAccelerator from "../sections/home/mstars-accelerator";
import OurSolution from "../sections/home/our-solution";
import OurSuccess from "../sections/home/our-success";
import Partners from "../sections/home/partners";
import Posts from "../sections/home/posts";
import ProblemsSolution from "../sections/home/problems-solution";
import VirtualSignTranslator from "../sections/home/virtual-sign-translator";
import WhyWeDidIt from "../sections/home/why-we-did-it";

export default function Home() {
  return (
    <div className="">
      <IntroSection />
      <OurSuccess />
      <WhyWeDidIt />
      <OurSolution />
      <VirtualSignTranslator />
      <ProblemsSolution />
      <MstarsAccelerator />
      <CreateValue />
      <Posts />
      <Partners />
      <AppAbout />
      <Footer />
    </div>
  );
}
