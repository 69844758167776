import React from "react";
import TitleMain from "../../components/main/title-main";
import { useTranslation } from "react-i18next";

export default function OurSuccess() {
  const { t } = useTranslation();
  const succesData = [
    { title: t("home_ach_nat_comp"), src: "/assets/success/Startup.png" },
    { title: t("home_ach_innov_comp"), src: "/assets/success/Monx.png" },
    { title: t("home_ach_next_gen"), src: "/assets/success/Nextgen.png" },
    { title: t("home_ach_service_voucher"), src: "/assets/success/procraft.png" }
  ];
  return (
    <div className="mt-8 sm:mt-12 lg:mt-28 2xl:px-48 md:px-20 sm:px-12 px-6">
      <TitleMain src="/assets/icons/title-success.svg" title={t("home_title_our_achievements")} />
      <div className="grid sm:grid-cols-4 grid-cols-2 gap-6 mt-10 2xl:px-48 ">
        {succesData.map((item, index) => {
          return <SuccessCard key={index} src={item.src} title={item.title} />;
        })}
      </div>
      <div className="hidden w-full flex justify-end sm:flex">
        <img alt="" src="/assets/success/arrow.svg" />
      </div>
    </div>
  );
}

export function SuccessCard({ src = "/", title = "" }) {
  return (
    <div className="flex flex-col justify-center items-center">
      <img alt="" src={src} className="w-fit" />
      <h3 className="text-sm text-gray-900 font-semibold sm:ml-5">{title}</h3>
    </div>
  );
}
