import { useTranslation } from "react-i18next";
import React from "react";
import TitleMain from "../../components/main/title-main";

export default function CreateValue() {
  const { t } = useTranslation();

  const valueCards = [
    {
      icon: "/assets/icons/user-shield.svg",
      title: t("home_equal_society_subtitle"),
      desc: t("home_equal_society_desc")
    },
    {
      icon: "/assets/icons/search-alt.svg",
      title: t("home_research_com_subtitle"),
      desc: t("home_research_com_desc")
    },
    {
      icon: "/assets/icons/desktop-computer.svg",
      title: t("home_tech_make_subtitle"),
      desc: t("home_tech_make_desc")
    },
    {
      icon: "/assets/icons/rocket.svg",
      title: t("home_tech_inn_subtitle"),
      desc: t("home_tech_inn_desc")
    }
  ];
  return (
    <div className="pt-28 2xl:px-48 md:px-20 sm:px-12 px-6 bg-blue-50">
      <TitleMain src="/assets/icons/star-icon.svg" title={t("home_title_value")} />
      <div className="grid sm:grid-cols-2 sm:gap-6 gap-3 pb-32 pt-12">
        {valueCards.map((item, index) => {
          return <ValueCard key={index} icon={item.icon} title={item.title} desc={item.desc} />;
        })}
      </div>
    </div>
  );
}
export function ValueCard({ icon, title, desc }) {
  return (
    <div className="px-6 py-6 rounded-3xl bg-white">
      <img alt="" src={icon} />
      <h3 className="text-2xl font-medium pt-5 pb-4">{title}</h3>
      <h5 className="text-sm text-gray-600">{desc}</h5>
    </div>
  );
}
