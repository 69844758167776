import React from "react";

export default function TitleMain({ title = "[Гарчиг байхгүй байна]", src = "/assets/icons/title-placeholder.svg", toLeft = false }) {
  return (
    <div className={`flex ${toLeft ? "" : "justify-center"} items-center gap-6 p-1" flex-col sm:flex-row sm:w-auto`}>
      <img alt="" className="h-12" src={src} />
      <h1 className=" uppercase sm:text-3xl text-xl font-semibold text-center">{title}</h1>
    </div>
  );
}
