import React, { useState, useEffect, useRef } from "react";
import TitleMain from "../../components/main/title-main";
import audio from "../../audio2.mp3";
import ShareButton from "../../components/share-button";
import { useTranslation } from "react-i18next";

export default function VirtualSignTranslator() {
  const { t } = useTranslation();
  const song = useRef();
  song.current = new Audio();
  useEffect(() => {
    song.current.src = audio;
  }, []);
  const [value, setValue] = useState(t("home_anu_placeholder"));
  return (
    <div className="2xl:px-48 md:px-20 sm:px-12 px-6 xl:py-28 py-8 sm:py-12">
      <TitleMain src="/assets/icons/sign-translate-logo.svg" title={t("home_virtual_sign_lang_interpreter_anu")} />
      <div className="flex sm:gap-8 gap-6 mt-12 flex-col sm:flex-row ">
        <img alt="" src="/assets/Anu.gif" className="md:w-72 lg:w-96 w-full rounded-md" />
        <div className="w-full ">
          {/* auto multiple line text area */}
          <textarea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="w-full h-32 bg-gray-100 p-6 border-2 rounded-r-3xl rounded-tl-3xl overflow-auto"
          />
          <div className="flex gap-4 sm:mt-12 mt-8 sm:flex-row flex-col">
            <button
              onClick={() => {
                song.current.play(audio);
              }}
              className="flex sm:min-w-max max-w-max items-center gap-3 px-6 rounded-full py-4 bg-blue-700 text-white">
              <img alt="" className="w-6 " src="/assets/icons/play.svg" />
              {t("home_btn_listen_translation")}
            </button>
            <ShareButton title={t("home_btn_try")} to="try-it" />
          </div>
        </div>
      </div>
    </div>
  );
}
