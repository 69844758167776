import { useTranslation } from "react-i18next";
import React from "react";

export default function IntroSection() {
  const { t } = useTranslation();
  return (
    <div
      className="h-5/6 2xl:px-48 md:px-12 sm:px-6 px-4 justify-between items-start md:items-center flex md:flex-row flex-col pt-10"
      style={{
        background: "linear-gradient(0deg, #E2EBFE 0%, rgba(226, 235, 254, 0) 100%)"
      }}>
      <div>
        <h1 className="xl:text-6xl md:text-4xl sm:text-2xl text-xl font-semibold mb-4 md:mb-12">
          {t("home_slogan_1")}
          <br /> {t("home_slogan_2")}
        </h1>
        <p className="mb-6">
          {t("home_desc_virtual_interpreter_app")} <br />
          <strong className="font-semibold">{t("stay_tuned")}</strong>
        </p>
        <div className="flex gap-4">
          <button className="flex gap-3 px-5 py-3 border-solid border rounded-full bg-blue-200 text-xs items-center">
            <img alt="" className="h-6" src="/assets/icons/apple-logo.svg" /> Apple store
          </button>
          <button className="flex gap-3 px-5 py-3 border-solid border rounded-full bg-cyan-100 text-xs items-center">
            <img alt="" className="h-6" src="/assets/icons/google-play-logo.svg" />
            Google play
          </button>
        </div>
      </div>
      <img alt="" className="mt-5 md:w-96 lg:w-max" src="/assets/anu.png" />
    </div>
  );
}
