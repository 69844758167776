import React from "react";
import Banner from "../sections/aboutUs/banner";
import OurAmbition from "../sections/aboutUs/our-ambition";
import History from "../sections/aboutUs/history";
import OurTeam from "../sections/aboutUs/our-team";
import Roadmap from "../sections/aboutUs/roadmap";
import PeopleTalkings from "../sections/aboutUs/people-talkings";
import Footer from "../sections/footer";

export default function AboutUs() {
  return (
    <div>
      <Banner />
      <OurAmbition />
      <OurTeam />
      <History />
      <Roadmap />
      <PeopleTalkings />
      <Footer />
    </div>
  );
}
