import React from "react";
import TitleMain from "../../components/main/title-main";
import { useTranslation } from "react-i18next";

export default function History() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="2xl:px-48 md:px-20 sm:px-12 px-6 pt-12 sm:pt-28">
        <TitleMain toLeft title={t("about_our_history")} src="/assets/icons/star-icon-yellow.svg" />
      </div>
      <div className="pt-8 sm:mt-16">
        <div className="2xl:px-48 md:px-20 sm:px-12 px-6 mt-4 sm:mt-12">
          <img alt="" src="/assets/history-pc.svg" className="hidden lg:block w-full" />
          <img alt="" src="/assets/history-tablet.svg" className="hidden md:block lg:hidden w-full" />
          <img alt="" src="/assets/history-tablet-small.svg" className="hidden sm:block md:hidden w-full" />
          <img alt="" src="/assets/history-phone.svg" className="block sm:hidden w-full" />
        </div>
      </div>
    </div>
  );
}
