import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import TitleMain from "../../components/main/title-main";
import { t } from "i18next";
import { HANDPRO_CDN_URL } from "../../api";

export default function PeopleTalkings() {
  const externalPosts = [
    {
      thumbnail: "mlspgov.png",
      title: "Г.Оргилболд: Сонсголын бэрхшээлтэй иргэд виртуал дохионы хэлмэрчийн тусламжтайгаар утсаар ярьдаг болно",
      link: "https://mlsp.gov.mn/content/detail/2386"
    },
    {
      thumbnail: "tengertv.png",
      title: "HANDPRO ХХК ВИРТУАЛ ДОХИОНЫ ХЭЛМЭРЧ ХӨГЖҮҮЛЖ БАЙНА | Б.УУГАНБАЯР",
      link: "https://www.youtube.com/watch?v=h3w_ze9skbM"
    },
    {
      thumbnail: "postby.png",
      title: 'Хөрөг нийтлэл: Э.Энхтуул "Миний мөрөөдөл их энгийн шүү дээ"',
      link: "https://postby.mn/@HandPro/cIzuwByiBS7"
    }
  ];
  const { t } = useTranslation();
  const [active, setActive] = useState(0);
  return (
    <div className="2xl:px-48 md:px-12 sm:px-6 px-3 py-8 sm:py-28">
      <TitleMain toLeft title={t("about_people_talking")} src="/assets/icons/circle-video.svg" />
      <h1 className="text-lg mt-8 mb-8 sm:mb-12">
        {t("about_news_media_channels_about_us_1")} <br />
        {t("about_news_media_channels_about_us_2")}
      </h1>
      <div className="flex gap-3 ">
        {["Бүгд"].map((item, index) => {
          // {["Бүгд", "Нийтлэл", "Видео"].map((item, index) => {
          return (
            <button
              onClick={() => {
                setActive(index);
              }}
              className={`${
                active === index ? "bg-blue-600  text-white" : "bg-white "
              } hover:bg-blue-100 box-border hover:text-black transition-all duration-200 border border-blue-50 rounded-full px-4 py-2"`}>
              {item}
            </button>
          );
        })}
      </div>
      <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
        {externalPosts.map((p, index) => (
          <PosterItem key={index} post={p} />
        ))}
      </div>
      <div className="flex justify-center mt-12">
        <ReactPaginate
          className="flex gap-3"
          onPageChange={(data) => {
            console.log(data);
          }}
          initialPage={0}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          renderOnZeroPageCount={null}
          pageCount={1}
          activeClassName="bg-blue-600 text-white"
          pageClassName="border cursor-pointer h-10 w-10 items-center justify-center flex rounded-full hover:bg-blue-100 "
          previousLabel={<img alt="" src="/assets/icons/left.svg" className="h-10 w-10 rounded-full hover:bg-blue-100 duration-200" />}
          nextLabel={<img alt="" src="/assets/icons/right.svg" className="h-10 w-10 rounded-full hover:bg-blue-100 duration-200" />}
        />
      </div>
    </div>
  );
}

function PosterItem({ post }) {
  return (
    <div className="border rounded-3xl overflow-hidden">
      <img alt="" src={`${HANDPRO_CDN_URL}/${post.thumbnail}`} className="w-full aspect-video object-cover" />
      <div className="flex flex-col gap-6 px-4  py-6">
        {/* <img alt="" className="h-10 w-10" src={(type = "видео" ? "/assets/icons/circle-video.svg" : "/assets/icons/circle-poster.svg")} /> */}
        <h1 className="text-xl line-clamp-2">{post.title}</h1>
        <a
          href={post.link}
          target="_blank"
          rel="noreferrer"
          className="flex gap-3 px-6 text-sm rounded-full py-4 border shadow box-border  font-bold">
          {t("btn_detail")} <img alt="" src="/assets/icons/right-arrow.svg" className="h-5" />
        </a>
      </div>
    </div>
  );
}
