import { useTranslation } from "react-i18next";
import React from "react";
import TitleMain from "../../components/main/title-main";

export default function MstarsAccelerator() {
  const { t } = useTranslation();
  return (
    <div
      className="2xl:px-48 md:px-20 sm:px-12 px-6 sm:py-32 py-8"
      style={{
        background: "linear-gradient(180deg, #CCDCFD 0%, #E1E0FC 100%)"
      }}>
      <TitleMain src="/assets/icons/circle-mstars.svg" title="MSTARS ACCELERATOR" />
      <div className=" flex items-center justify-between mt-12 p-6 px-12 bg-white rounded-3xl flex-col sm:flex-row">
        <img alt="" className="sm:hidden" src="/assets/mstars.png" />
        <div className="flex flex-col gap-4">
          <img alt="" className="h-9 w-9" src="/assets/icons/rocket.svg" />
          <h1 className="text-2xl font-bold text-gra">{t("M stars accelerator")}</h1>
          <p className="text-sm text-gray-600">
            {t("home_mstars_desc_1")} <br />
            {t("home_mstars_desc_2")} <br />
            {t("home_mstars_desc_3")} <br />
            {t("home_mstars_desc_4")}
          </p>
          <button className="px-4 py-2 mt-2 rounded-full bg-blue-200 font-bold text-sm max-w-max bg-">Mstars Accelerator</button>
        </div>
        <img alt="" className="h-80 hidden sm:block" src="/assets/mstars.png" />
      </div>
    </div>
  );
}
