import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";
import i18n from "i18next";

export default function Navbar() {
  const { t } = useTranslation();
  const phaths = [
    {
      href: "about-us",
      title: t("header_about_us")
    },
    {
      href: "our-projects",
      title: t("header_projects")
    },
    {
      href: "posts",
      title: t("header_news")
    },
    {
      href: "try-it",
      title: t("header_try")
    }
  ];

  const [lang, setLang] = useState({
    show: false,
    value: localStorage.getItem("language") ? localStorage.getItem("language") : "mn"
  });
  const [menu, setMenu] = useState(false);
  const menuRef = useRef();

  const [accessible, setAccessible] = useState({ show: false, value: 0 });
  const langRef = useRef();
  const accessibleRef = useRef();
  useOnClickOutside(langRef, () => setLang({ ...lang, show: false }));
  useOnClickOutside(accessibleRef, () => setAccessible({ ...accessible, show: false }));
  useOnClickOutside(menuRef, () => setMenu(false));

  useEffect(() => {
    localStorage.setItem("language", lang.value);
    i18n.changeLanguage(lang.value);
  }, [lang]);

  return (
    <nav className="md:px-12 px-4 z-30 py-4 flex justify-between items-center sticky top-0 bg-white shadow-[0px_0px_5px_rgba(3,102,214,0.3)]">
      <div className="flex gap-12 items-center">
        <Link to={"/"}>
          <div className="">
            <img alt="" className="h-12" src="/assets/icons/handpro-logo.svg" />
          </div>
        </Link>
        {/* menu */}
        <div
          ref={menuRef}
          className={`${
            menu ? "-translate-x-full" : " "
          } shadow-md xl:shadow-none transition-all fixed duration-200 xl:translate-x-0 xl:static left-full top-0 z-10 flex flex-col justify-between h-screen xl:h-auto bg-white`}
          onClick={() => {
            setMenu(false);
          }}>
          <div className=" xl:flex-row py-3 w-[100vw] sm:w-[340px] xl:w-auto bg-white flex-col xl:bg-none right-0  xl:h-auto xl:flex flex gap-0  2xl:gap-6">
            <button onClick={() => setMenu(false)} className="ml-auto md:ml-3 mx-3 mb-6 xl:hidden">
              <img alt="" src="/assets/icons/close-button.svg" className="h-14 w-14" />
            </button>
            <Link to={"/donate"} className="block ml-4 mb-4 md:hidden">
              <button className="font-extrabold text-sm border px-6 py-4 min-w-max bg-blue-600 text-white rounded-full" onClick={() => {}}>
                {t("header_donate")}
              </button>
            </Link>
            {phaths.map((item, index) => (
              <Link key={index} to={item.href}>
                <h1 className=" px-4 py-4 hover:bg-gray-200 xl:hover:bg-white hover:color- font-bold text-sm">{item.title}</h1>
              </Link>
            ))}
          </div>
          <div className="w-full px-4 mb-10 xl:hidden">
            <div className="flex w-full flex-col gap-6 ">
              <h1 className="text-sm font-bold">Үйлчилгээний бодлого</h1>
              <h1 className="text-sm font-bold">Нууцлалын бодлого</h1>
              {/* <button className="flex w-max items-center gap-2 px-6 py-3  rounded-full border-2 border-blue-200 translation-all hover:bg-blue-100">
                <img alt="" src="/assets/icons/inbox.svg" />
                Санал хүсэлт илгээх
              </button> */}

              <div>
                <p className="text-sm sm:text-start text-center py-4 sm:py-0 ">Бүх эрх хуулиар хамгаалагдсан © 2023 он. Хэйнд Про ХХК</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gap-8 items-center md:flex">
        <Link to={"/donate"} className="hidden md:block">
          <button className="font-extrabold text-sm border px-6 py-4 min-w-max bg-blue-600 text-white rounded-full" onClick={() => {}}>
            {t("header_donate")}
          </button>
        </Link>
        {/* language dropdown option picker */}
        <div className="flex gap-2">
          <div
            ref={langRef}
            onClick={() => {
              setLang({ ...lang, show: !lang.show });
            }}
            className="relative">
            <button className="font-mono duration-200 font-extrabold text-sm hover:bg-slate-200 px-6 py-4 rounded-full flex gap-4 items-center">
              {lang.value.toUpperCase()} <img alt="" src="/assets/icons/dropdown.svg" className="min-w-max" />
            </button>
            <div
              className={`${
                lang.show ? "" : "hidden"
              } absolute gap-1 text-sm flex flex-col right-0 -bottom-3 translate-y-full border bg-white shadow-sm p-3 rounded-3xl min-w-max`}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setLang({ show: false, value: "mn" });
                }}
                className=" px-4 py-1 rounded-full font-extrabold hover:bg-blue-200  transition-all duration-200 flex min-w-max items-center gap-2">
                <img alt="" className="w-6" src="/assets/icons/mn-flag.svg" />
                Монгол хэл
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setLang({ show: false, value: "en" });
                }}
                className="px-4 py-1 text-sm rounded-full font-extrabold hover:bg-blue-200 transition-all duration-200 flex min-w-max items-center gap-2">
                <img alt="" className="w-6" src="/assets/icons/en-flag.svg" />
                English
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setLang({ show: false, value: "jp" });
                }}
                className="px-4 py-1 text-sm rounded-full font-extrabold hover:bg-blue-200 transition-all duration-200 flex min-w-max items-center gap-2">
                <img alt="" className="w-6 border" src="/assets/icons/jp-flag.svg" />
                Japanese
              </button>
            </div>
          </div>
          <div className="relative" ref={accessibleRef}>
            <button
              className="h-12 w-12"
              onClick={(e) => {
                setAccessible({ ...accessible, show: !accessible.show });
              }}>
              <img alt="" src="/assets/icons/accessible-button.svg" />
            </button>
            <div
              className={`${
                accessible.show ? "" : "hidden"
              } absolute min-w-max gap-1 text-sm flex flex-col right-0 -bottom-3 translate-y-full border bg-white shadow-sm p-3 rounded-3xl`}>
              <div className="flex px-4 justify-between">
                <h1 className="font-extrabold ">
                  Үсгийн хэмжээ <br />
                  тохируулах
                </h1>
                <button
                  onClick={() => {
                    setAccessible({ show: false, value: accessible.value });
                  }}>
                  <img alt="" src="/assets/icons/close-button.svg" />
                </button>
              </div>
              <div className="flex px-4 gap-3 mt-6 items-center mb-3">
                <button
                  className="px-10 py-4 border rounded-full font-extrabold"
                  onClick={(e) => {
                    e.stopPropagation();
                    setAccessible({ show: true, value: accessible.value + 1 });
                  }}>
                  A+
                </button>
                <div className="border h-7"></div>
                <button
                  className="px-10 py-4 border rounded-full font-extrabold"
                  onClick={(e) => {
                    e.stopPropagation();
                    setAccessible({ show: true, value: accessible.value - 1 });
                  }}>
                  A-
                </button>
              </div>
              <hr />
              <div className="px-3">
                <div>
                  <h1 className="text-xs font-extrabold mt-3 mb-3">
                    Мөн дараах 2 товчийг зэрэг дарж <br /> тохируулж болно.
                  </h1>
                  <ul className="list-disc list-inside">
                    <li className="text-xs mb-3">
                      Ctrl болон + товч /Windows, <br />
                      Linux, болон Chrome OS систем/
                    </li>
                    <li className="text-xs">⌘ болон + товч /Mac OS систем/</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* button */}
          <button
            className="xl:hidden"
            onClick={() => {
              setMenu(!menu);
            }}>
            <img alt="" src="/assets/icons/burger-menu.svg" className=" h-12 w-12" />
          </button>
        </div>
      </div>
    </nav>
  );
}
