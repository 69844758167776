import axios from "axios";

export const CLASSIFIER_URL = process.env.REACT_APP_CLASSIFIER_URL || "";
export const SL2TXT_URL = process.env.REACT_APP_SL2TXT_URL || "";
export const HANDPRO_API_URL = process.env.REACT_APP_HANDPRO_API_URL || "";
export const HANDPRO_CDN_URL = process.env.REACT_APP_HANDPRO_CDN_URL || "";

export async function listNews(text) {
  try {
    const res = await axios.post(`${HANDPRO_API_URL}/public`, {
      eventType: "NEWS_SEARCH",
      criteria: text ? text : "",
    });
    return res.data.response;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export function extractDate(unixTimestamp) {
  return new Date(parseInt(unixTimestamp) * 1000).toISOString().split("T")[0];
}
