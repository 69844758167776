import { useTranslation } from "react-i18next";
import React from "react";

export default function Banner() {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-400">
      <div
        className="w-full  h-[244px] sm:h-[300px] md:h-[400px] xl:h-[516px] flex items-end bg-cover bg-no-repeat bg-bottom"
        style={{
          background: "linear-gradient(360deg, rgba(19, 57, 253, 0.6) 0%, rgba(6, 26, 128, 0) 100%), url(/assets/team-members.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}>
        <h1 className="text-lg md:text-2xl 2xl:px-48 md:px-12 sm:px-6 px-3 mb-20 text-white font-light">{t("about_banner_text")}</h1>
      </div>
    </div>
  );
}
