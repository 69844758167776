import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./sections/header/navbar";
import { BrowserRouter as Routers } from "react-router-dom";
import Home from "./page/home";
import OurProjects from "./page/our-projects";
import Donate from "./page/donate";
import AboutUs from "./page/about-us";
import Posts from "./page/posts";
import Feedback from "./page/feedback";
import FixedTranslate from "./components/main/fixed-translate";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./languages/en.json";
import jpTranslations from "./languages/jp.json";
import mnTranslations from "./languages/mn.json";
import TryIt from "./page/try-it";

export const MyContext = React.createContext(null);

i18n.use(initReactI18next).init({
  resources: {
    mn: {
      translation: mnTranslations
    },
    en: {
      translation: enTranslations
    },
    jp: {
      translation: jpTranslations
    }
  },
  lng: "mn",
  fallbackLng: "mn"
});
function App() {
  const [language] = useState(localStorage.getItem("language") ? localStorage.getItem("language") : "mn");
  return (
    <MyContext.Provider value={language}>
      <Routers>
        <FixedTranslate />
        <Navbar />
        <ScrollTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our-projects" element={<OurProjects />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/try-it" element={<TryIt />} />
          <Route path="*">404 Not Found</Route>
        </Routes>
      </Routers>
    </MyContext.Provider>
  );
}

export default App;

function ScrollTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return <></>;
}
