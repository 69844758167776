import React from "react";

export default function ShareButton({ title, to }) {
  return (
    <a href={to}>
      <button className="flex gap-3 px-6 text-sm rounded-full py-4 border-2 border-blue-200 font-bold uppercase hover:bg-blue-100 transition-colors ">
        {title} <img alt="" src="/assets/icons/right-arrow.svg" className="h-5" />
      </button>
    </a>
  );
}
