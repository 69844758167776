import React from "react";

export default function FixedTranslate() {
  return (
    <div className="z-50 fixed select-none top-1/2 duration-200 transition-all left-full rounded-l-full overflow-hidden -translate-y-1/2  hover:-translate-x-full -translate-x-14">
      <div className="min-w-max flex h-14 gap-4 p-4 bg-[#031A47]  cursor-pointer">
        <img alt="" src="/assets/icons/translate.svg" className="h-21" />
        <h1 className="text-white">Виртуал хэлмэрч</h1>
      </div>
    </div>
  );
}
