import React from "react";

export default function Title({ title }) {
  return (
    <div className="flex md:pl-9 items-center">
      <div className="w-3 h-6 bg-blue-600 rounded-xl"></div>
      <h3 className="md:text-2xl text-2xl font-semibold pl-3 uppercase">
        {title}
      </h3>
    </div>
  );
}
